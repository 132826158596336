$(document).ready(function () {
// Show modal when clicked
    $('.open-pwi-modal').on('click', function (event) {
        $(".pwi-modal#" + event.target.dataset.modalId).removeClass('hidden');
    });

// Hide modal when clicked
    $('.close-pwi-modal').on('click', function (event) {
        if ($(event.target).hasClass('pwi-modal-backdrop')) {
            $(event.target).parent().addClass('hidden');
        } else {
            $('.pwi-modal#' + event.target.dataset.modalId).addClass('hidden');
        }
    });
});